import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { typeT21sb, typeT38sb } from '../../styles/typography/presets';
import TextCombo from '../shared/TextCombo';


const PrincipleItem = styled.li`
  padding-left: 27px;
`;

const PrincipleItems = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Title = styled.div`
  ${typeT21sb};
`;

const CustomContainer = styled(Container)`
  padding: 60px 0;
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }
  ${PrincipleItems} {
    grid-column: 2 / span 8;
    
    ${PrincipleItem} {
      margin-bottom: 42px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  @media(min-width: ${breakpoints.lg}) {
    padding: 120px 0;
    ${Title} {
      ${typeT38sb};
      grid-column: 2 / span 8;
      margin-bottom: 87px;
    }
    ${PrincipleItems} {
      grid-column: 2 / span 8;
      
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 75px;
      grid-row-gap: 87px;
      
      ${PrincipleItem} {
        margin-bottom: 0;
      }
      
      ${PrincipleItem}:nth-child(4n+1) {
        grid-column: 1 / span 1;
      }
      ${PrincipleItem}:nth-child(4n+2) {
        grid-column: 2 / span 1;
      }
      ${PrincipleItem}:nth-child(4n+3) {
        grid-column: 2 / span 1;
      }
      ${PrincipleItem}:nth-child(4n+4) {
        grid-column: 3 / span 1;
      }
    }
  }
`;

const ValuesSection = ({pageData}) => (
  <CustomContainer id={'values'}>
    <Title>{pageData.principles_title}</Title>
    <PrincipleItems>
      {pageData.principles.map((principle, index) => (
        <PrincipleItem key={index}>
          <TextCombo
            title={(index + 1).toString().padStart(2, '0')}
            message={principle.title}
            briefHtml={principle.brief.html}
          />
        </PrincipleItem>
      ))}
    </PrincipleItems>

  </CustomContainer>
);

export default ValuesSection;