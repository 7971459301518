import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts';
import Hero from '../components/shared/Hero';
import InitiativesSection from '../components/services/InitiativesSection';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import Principles from '../components/principles/Principles';


class PrinciplesPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page(this.props.data.prismicPrinciplesPage.data.hero_title, {
      title: `${this.props.data.prismicPrinciplesPage.data.hero_title} | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const {data} = this.props;
    const pageData = data.prismicPrinciplesPage.data;

    return (
      <Layout reverseLogo>
        <Helmet>
          {titleTags(`${pageData.hero_title} | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.hero_brief.text)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={pageData.hero_title}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
          largerGradient={true}
        />
        <Principles
          pageData={pageData}
        />
        {/*<InitiativesSection pageData={pageData}/>*/}
      </Layout>
    );
  }
}


export default PrinciplesPage;

export const query = graphql`
  query PrinciplesPageQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicPrinciplesPage {
      data {
        hero_title
        hero_message
        hero_brief {
          html
          text
        }
        hero_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        principles_title
        principles {
          title
          brief {
            html
            text
          }
        }
      }
    }
  }
`;
